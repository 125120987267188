@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

/* .box-perguntas {
  @apply bg-white border-2 border-border text-center w-4/5;
}
.text-message {
  @apply font-poppins font-bold mx-auto text-3xl mt-4 bold md:text-2xl;
} */

.text-message {
  @apply font-poppins font-bold mx-auto text-3xl mt-4 md:text-2xl;
}
.box-perguntas {
  @apply bg-white border-2 border-border text-center w-4/5;
}
.button-regua {
  @apply py-2 px-4 border-2 md:px-3 md:py-1 font-medium hover:text-white transition-all duration-200;
}

.button-red {
  @apply border-red-600 hover:bg-red-600;
}
.button-yellow {
  @apply border-yellow-500 hover:bg-yellow-500;
}
.button-green {
  @apply border-green-500 hover:bg-green-500;
}
.btn-red-click {
  @apply bg-red-600 text-white;
}
.btn-yellow-click {
  @apply bg-yellow-500 text-white;
}
.btn-green-click {
  @apply bg-green-500 text-white;
}
